<template>
    <div class="body">
        <Header />
        
        <CompanyHeader :title="title" />
        <!-- <section class="page-header parallax page-header-text-light mb-0 bg_img black_cover" :style="`background-image: url(${require(`@/assets/img/bg/4.jpg`)})`">
            <div class="container py-5 my-5">
                <div class="row pt-5 mt-5">
                    <div class="col-md-12">
                        <ul class="breadcrumb" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="100">
                            <li><router-link to="/">Home</router-link></li>
                            <li><router-link to="/company">business</router-link></li>
                            <li class="active">사업 소개</li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p class="font-weight-bold display-4">사업 소개</p>
                    </div>
                </div>
            </div>
        </section> -->
        <div class="main">
            <section class="section">
                <div class="container">
                    <div class="unit rt pt-5 pt-lg-0">
                        <div class="text-center"><img :src="`${require(`@/assets/img/business/${selectItem.title_img}`)}`" height="50" class="mx-auto mb-5"></div>
                        <div class="position-relative wrap pt-4 pt-lg-0">
                            <div class="position-absolute title_img">
                                
                            </div>
                            <div class="txt_box">
                                <div class="bg-light-blue position-absolute w-100 h-100"></div>
                                <div class="txt p-5">
                                    <div class="inner mt-3">
                                        <h4 class="mb-4 fw-700">{{selectItem.sub_title}}</h4>
                                        <h5 class="lh-px-24 mb-5">{{selectItem.desc}}</h5>
                                        <strong class="text-primary h6 fw-700">서비스 특장점</strong>
                                        <ul class="text-dark mt-3">
                                            <li v-for="(item,index) in selectItem.advantages" :key="index" class="mb-4">
                                                <div class="h6 fw-700">
                                                    <span>{{index+1}}. </span>
                                                    {{item.title}}
                                                </div>
                                                <div class="mt-1">
                                                    {{item.desc}}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="img_box bg_img" :style="`background-image: url(${require(`@/assets/img/business/${selectItem.img}`)})`">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/MainHeader.vue'
import CompanyHeader from '../Company/CompanyHeader.vue'
import Footer from '@/components/common/Footer.vue'
export default {
  components:{
    Header, Footer, CompanyHeader
  },
  data(){
    return{
        title:'사업 소개',
        item:[
            {
                title: '마이파킹',
                title_img:'03.png',
                sub_title:'모빌리티 공유 주차 플랫폼',
                desc:`마이주차는 모빌리티 기술을 바탕으로 원하는 주차 정보를 실시간으로 정확하게 사용자에게 제공하고, 사용 가능한 주차 공간을 매칭시켜주는 서비스 플랫폼입니다.`,
                img:'my-parking-1.png',
                advantages: [
                    {
                        title: '서비스 이용자',
                        desc: '언제 어디서나 마이주차 앱을 통해 손쉽게 주차면을 검색/예약으로 주차 배회시간 절약합니다.'
                    },
                    {
                        title: '주차면 제공 파트너',
                        desc: '유휴 주차면을 보유하고 있는 파트너가 주차면을 사용하지 않은 시간대에 주차하면, 공유를 통해 부가 수익을 창출합니다.'
                    },
                    {
                        title: '공공기관 & 지방자치단체',
                        desc: '주차장 부족에 따른 불법주정차 문제 발생으로 교통혼잡과 긴급차량 통행방해 및 보행자의 안전까지 위협받는 등의 문제를 해소합니다.'
                    },
                ]
            },
        ]
    }
  },
  computed:{
    selectItem(){
        return this.item[0];
    },
  },
}
</script>
<style scoped>
    .bg-light-blue{
        background-color: #f1f3f7;
    }
    .unit{
        margin-bottom: 250px;
    }
    .txt_box{
        width: 65%;
    }
    .txt_box .txt{
        position: relative;
        z-index: 2;
    }
    .img_box{
        width: 40%;
        height: 0;
        padding-bottom: 40%;
        z-index: 2;
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
    }
    .rt .img_box, .lt .txt_box{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    .rt .img_box, .lt .txt_box{
        right: 0;
    }
    .rt .txt_box{
        position: relative;
    }
    .rt .txt_box .inner{
        margin-right: 10%;
    }
    .lt .txt_box .inner{
        margin-left: 20%;
    }
    .title_img{
        left: 20px;
        bottom: 100%;
        transform: translateY(50%);
        z-index: 10;
    }

    @media (max-width: 991.9px){
        .wrap{
            display: flex;
            flex-direction: column;
        }
        .txt_box{
            order: 2;
            width: 100%;
            margin-top: -70px;
        }
        .img_box{
            order: 1;
            width: 80%;
            padding-bottom: 80%;
            margin: 0 auto;
        }
        .rt .img_box, .lt .txt_box{
            position: relative;
            transform: initial;        
        }
        .rt .txt_box .inner{
            margin-right: 0%;
            padding-top: 100px;
        }
        .lt .txt_box .inner{
            margin-left: 0%;
            padding-top: 100px;
        }
        
        .title_img{
            left: 50%;
            transform: translate(-50%, 0%);
            z-index: 10;
        }
    }
    
@media (max-width: 575.9px){
    .page-header .display-4{
        font-size: 23px;
    }
}
</style>