<template>
    <div class="body">
        <Header />
        <CompanyHeader :title="title" />
        <!-- <section class="page-header parallax page-header-text-light mb-0 bg_img black_cover" :style="`background-image: url(${require(`@/assets/img/bg/4.jpg`)})`">
            <div class="container py-5 my-5">
                <div class="row pt-5 mt-5">
                    <div class="col-md-12">
                        <ul class="breadcrumb" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="100">
                            <li><router-link to="/">Home</router-link></li>
                            <li class="active">business</li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p class="font-weight-bold display-4">모빌리티 플랫폼 기반 전자결제 사업</p>
                    </div>
                </div>
            </div>
        </section> -->
        <div class="main min-h-px-600">
            <!-- <section class="section">
                <div class="container">
                    <ul class="nav sort-source align-items-center justify-content-center mb-4">
                        <li class="nav-item mr-3 cursor-pointer" :class="{'text-primary':category===0}" @click="category=0">
                            SHOW ALL</li>
                        <li class="nav-item mr-3 cursor-pointer" :class="{'text-primary':category===1}"  @click="category=1">
                            공유 주차장</li>

                        <li class="nav-item cursor-pointer" :class="{'text-primary':category===3}"  @click="category=3">
                            전자결제대행</li>
                    </ul>

                    <div class="py-3">
                        <ul class="portfolio-list portfolio-list-style-2 sort-destination d-flex flex-wrap">
                            <li class="col-sm-6 col-md-4 p-0 mb-3 isotope-item" v-for="(item,index) in filterList" :key="index">
                                <div class="portfolio-item hover-effect-3d text-center">
                                    <router-link :to="item.link">
                                        <div class="mb-4 min-height-300 d-flex align-items-center justify-content-center">
                                            <img :src="item.src" alt="" class="img-fluid" style="max-height:300px">
                                        </div>
                                        <h2 class="text-4 font-weight-bold">{{ item.title }}</h2>
                                        <p>{{ item.desc }}</p>
                                        <a href="#" class="btn btn-link font-weight-bold text-decoration-none align-items-center d-inline-flex pl-0">
                                            VIEW MORE <i class="fas fa-angle-right text-3 ml-3"></i>
                                        </a>
                                    </router-link>
                                </div>
                            </li>
                        </ul>
                    </div>

                    </div>
            </section> -->
            <section class="section">
                <div class="container text-center">
                    <h2 class="lh-px-50 fw-700 pb-5">
                        모빌리티 플랫폼을 기반으로 온라인과 오프라인 상거래에<br>
                        필수적인 전자결제 서비스를 제공합니다.<br>
                    </h2>
                    <h4>「전자결제사업(PG : Payment Gateway」</h4>
                    <div class="h-px-100"></div>
                    <hr>
                    <div class="h-px-100"></div>
                    <div class="d-flex flex-column flex-center-center">
                        <img src="@/assets/img/TGP_origin_symbol.png" width="100" class="mb-5">
                        <div class="btn btn-lg btn-outline-primary p-4" @click="$router.push('/pay')">
                            <span class="fs-px-20">TheGoodPay 서비스</span>
                            <div class="w-px-40"></div>
                            <span class="fs-px-20"><i class="far fa-chevron-right"></i></span>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/MainHeader.vue'
import CompanyHeader from '../Company/CompanyHeader.vue'
import Footer from '@/components/common/Footer.vue'
export default {
  components:{
    Header, Footer,CompanyHeader
  },
  data(){
    return{
        title:'모빌리티 플랫폼 기반 전자결제 사업',
        category:0,
        list:[
            {
                src:require('@/assets/img/business/03.png'),
                type:1,
                title:'모빌리티 기반 공유 주차 플랫폼',
                desc:'• 공유주차, 모빌리티 부가서비스',
                link:'/business/1',
            },
            {
                src:require('@/assets/img/business/01.png'),
                type:3,
                title:'전자결제대행 시스템',
                desc:'• 간편결제, 신용카드결제 NFC 결제 등 관리',
                link:'/business/3',
            }
        ]
    }
  },
  computed:{
    filterList(){
        let sortList = this.list.filter((el) => el.type == this.category)
        console.log(this.category)
        console.log(sortList)
        return this.category==0?this.list:sortList
    }
  }
}
</script>
