<template>
    <div class="body">
        <Header />

        <div class="main">
            <CompanyHeader :title="title" />
            <section class="section">
                <div class="container">
                    <!-- <div class="row text-center mb-8">
                        <div class="col-md-10 col-lg-7 col-xl-6 mx-auto">
                            <div class="overflow-hidden">
                                <span class="d-block top-sub-title text-color-primary" data-appear-animation="maskUp">HISTORY</span>
                            </div>
                            <div class="overflow-hidden">
                                <h2 class="font-weight-bold mb-3" data-appear-animation="maskUp" data-appear-animation-delay="200">회사 연혁</h2>
                            </div>
                        </div>
                    </div> -->
                    <div class="flex flex-column">
                        <div class="row mb-5 align-items-start">
                            <div class="col-lg-3 pb-3 pb-lg-0">
                                <h2>2024</h2>
                            </div>
                            <div class="col-lg-9">
                                <ul>
                                    <li>통합전자결제서비스 “TheGoodPay” 서비스 개시</li>
                                    <li>전자지급결제대행 취득</li>
                                </ul>
                            </div>
                        </div>
                        
                        <hr class="mb-5">
                        <div class="row mb-5 align-items-start">
                            <div class="col-lg-3 pb-3 pb-lg-0">
                                <h2>2023</h2>
                            </div>
                            <div class="col-lg-9">
                                <ul>
                                    <li>에스에이지카타운, 공유주차 플랫폼 활성화를 위한 업무제휴</li>
                                    <li>탐코퍼레이션, 골프통합관리 플랫폼 서비스 업무 제휴</li>
                                    <li>공유 마이주차 플랫폼 베타 버전 오픈</li>
                                </ul>
                            </div>
                        </div>
                        <hr class="mb-5">
                        <div class="row mb-5 align-items-start">
                            <div class="col-lg-3 pb-3 pb-lg-0">
                                <h2>2022</h2>
                            </div>
                            <div class="col-lg-9">
                                <ul>
                                    <li>회사 설립</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>

        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/MainHeader.vue'
import CompanyHeader from './CompanyHeader.vue'
import Footer from '@/components/common/Footer.vue'
export default {
  components:{
    Header, CompanyHeader, Footer
  },
  data(){
    return{
        title:'회사 연혁',
        name: '',
        tel: '',
        company: '',
        email: '',
        message: '',
        file: '',
    }
  }
}
</script>

<style scoped>
li{
    font-size: 16px
}
</style>