<template>
    <div class="body">
        <Header />

        <div class="main">
            <CompanyHeader :title="title" :desc="desc" />
                
            <section class="section">
                <div class="container">
                    <div class="row mt-2">
                        <div class="col-md-6 col-lg-4 mb-4 mb-lg-0 cursor-pointer">
                            <div class="icon-box icon-box-style-5">
                                <div class="icon-box-info">
                                    <div class="icon-box-info-title mb-3">
                                        <p>회사명</p>
                                    </div>
                                    <h2 class="font-weight-bold text-5">주식회사 원성글로벌</h2>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 mb-4 mb-lg-0 cursor-pointer">
                            <div class="icon-box icon-box-style-5">
                                <div class="icon-box-info">
                                    <div class="icon-box-info-title mb-3">
                                        <p>대표이사</p>
                                    </div>
                                    <h2 class="font-weight-bold text-5">장주현</h2>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-4 mb-4 mb-md-0 cursor-pointer">
                            <div class="icon-box icon-box-style-5">
                                <div class="icon-box-info">
                                    <div class="icon-box-info-title mb-3">
                                        <p>설립일</p>
                                    </div>
                                    <h2 class="font-weight-bold text-5">2022년 09월 28일</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5 border-top pt-5">
                        <div class="col-md-6 col-lg-3 mb-4 mb-lg-0 cursor-pointer">
                            <div class="icon-box icon-box-style-5">
                                <div class="icon-box-info">
                                    <div class="icon-box-info-title mb-3">
                                        <p>자본금</p>
                                    </div>
                                    <h2 class="font-weight-bold text-5">10억</h2>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3 mb-4 mb-lg-0 cursor-pointer">
                            <div class="icon-box icon-box-style-5">
                                <div class="icon-box-info">
                                    <div class="icon-box-info-title mb-3">
                                        <p>주소</p>
                                    </div>
                                    <h2 class="font-weight-bold text-5">경기도 화성시 향남읍 발안로 679-14</h2>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3 mb-4 mb-md-0 cursor-pointer">
                            <div class="icon-box icon-box-style-5">
                                <div class="icon-box-info">
                                    <div class="icon-box-info-title mb-3">
                                        <p>대표번호</p>
                                    </div>
                                    <h2 class="font-weight-bold text-5">1533-4178</h2>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-lg-3 mb-4 mb-md-0 cursor-pointer">
                            <div class="icon-box icon-box-style-5">
                                <div class="icon-box-info">
                                    <div class="icon-box-info-title mb-3">
                                        <p>대표팩스</p>
                                    </div>
                                    <h2 class="font-weight-bold text-5">02-555-4178</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="aboutus" class="section bg-light-4">
                <div class="container">
                    <div class="row text-center mb-4">
                        <div class="col-md-10 col-lg-7 col-xl-6 mx-auto">
                            <div class="overflow-hidden">
                                <span class="d-block top-sub-title text-color-primary" data-appear-animation="maskUp">MAJOR BUSINESS</span>
                            </div>
                            <div class="overflow-hidden">
                                <h2 class="font-weight-bold mb-3" data-appear-animation="maskUp" data-appear-animation-delay="200">주요 사업</h2>
                            </div>
                            <div class="overflow-hidden mb-3">
                            </div>
                        </div>
                    </div>
                    <div class="row mb-5 mb-lg-0 pb-4 pb-lg-0">
                        <div class="col-6">
                            <div class="bg-white shadow rounded-3 p-5 cursor-pointer" @click="$router.push('/business/payment')">
                                <h3 class="flex-between-center mb-4">전자 결제 <i class="fal fa-chevron-right fs-px-21"></i></h3>
                                <p>고객이 전자상거래 사업을 영위하는데 필요한 모든 전자결제 솔루션 구축 및 서비스를 제공합니다.</p>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="bg-white shadow rounded-3 p-5 cursor-pointer" @click="$router.push('/business/myparking')">
                                <h3 class="flex-between-center mb-4">마이주차 플랫폼 <i class="fal fa-chevron-right fs-px-21"></i></h3>
                                <p>비효율적으로 운영되는 주차공간을 발굴하고 공유 운영을 통해 주차 문제를 해결하고 공유 주차 제공으로 수익 발생</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/MainHeader.vue'
import CompanyHeader from './CompanyHeader.vue'
import Footer from '@/components/common/Footer.vue'
export default {
  components:{
    Header, CompanyHeader, Footer
  },
  data(){
    return{
        title:'회사 개요',
        desc:`미래 모빌리티 산업을 선도하는 소프트웨어 중심 통합 플랫폼 서비스 제공을 기반으로<br>
통합전자결제(Payment Gateway) 솔루션을 제공하는 기업 원성글로벌은<br>
국내 최고의 기업이 되기 위하여 4차 산업혁명 시대에 글로벌 리더로 도약해 나갈 것이며,<br> 항상 새로운 기술 개발을 통해 고객이 편리하고 쉽게 사용할 수 있는 전자결제 서비스를 제공할 것을 약속 드립니다.`
    }
  }
}
</script>
<style lang="scss" scoped>
.info{
    
    margin-bottom: 4em;
    text-align: center;
    >.col-6{
        padding: 25px 0;
    }
    h5{
        margin-top: 15px;
        font-size: 1.5em;
    }   
}
</style>
