<template>
    <div class="body">
        <Header />

        <div class="main">
            <section class="parallax page-header-text-light mb-0">
                <div class="text-left">
                    <div class="d-flex flex-column flex-sm-row">
                        <div class="w-100 px-0">
                            <div class="bg_img black_cover w-100 h-px-400 pt-5" :style="`background-image: url(${require(`@/assets/img/bg/pr_1.jpg`)});`">
                            </div>
                        </div>
                        <div class="w-100 mb-0 bg-444 py-5 d-flex align-items-center text-left px-0">
                            <div class="pl-3 pl-sm-5 w-100">
                                <p class="pt-5 mt-5 d-none d-sm-block"></p>
                                <h1 class="font-weight-bold text-white">News</h1>
                                <p class="lead opacity-50 text-white">원성글로벌의 소식을 전해드립니다.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            
            <section class="section">
                <div class="container">

                    <div class="sidebar row mb-4">
                        <form class="sidebar-search col-md-4 ml-auto">
                            <div class="input-group">
                                <input type="text" class="form-control line-height-1 bg-light-5" name="s" id="s" placeholder="검색어를 입력해주세요">
                                <span class="input-group-btn">
                                    <button class="btn btn-light" type="submit"><i class="fas fa-search text-color-primary"></i></button>
                                </span>
                            </div>
                        </form>
                    </div>

                    <div class="custom_board_table">
                        <table class="table">
                            <thead>
                                <tr class="text-center">
                                    <th class="sm" scope="col">번호</th>
                                    <th scope="col">제목</th>
                                    <th scope="col">글쓴이</th>
                                    <th scope="col">조회수</th>
                                    <th scope="col">등록일자</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="text-left text-sm-center" v-for="(item,index) in list" :key="index">
                                    <th class="sm d-none d-sm-table-cell" scope="row">{{item.idx}}</th>
                                    <td class="text-left title">
                                        <router-link  :to="{
                                            name:'Post', 
                                            params: {
                                                category: 'news',
                                                idx: item.idx
                                            }
                                        }" class="d-block">{{item.title}}</router-link>
                                    </td>
                                    <td class="d-none d-sm-table-cell">{{item.writer}}</td>
                                    <td class="d-none d-sm-table-cell">{{item.views}}</td>
                                    <td class="date">{{item.date}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="row">
                        <div class="col mt-5">
                            <ul class="pagination justify-content-center">
                                <li class="page-item m-0">
                                    <a class="page-link" href="#"><i class="fas fa-chevron-left"></i></a>
                                </li>
                                <li class="page-item active m-0"><a class="page-link" href="#">1</a></li>
                                <!-- <li class="page-item m-0"><a class="page-link" href="#">2</a></li>
                                <li class="page-item m-0"><a class="page-link" href="#">3</a></li>
                                <li class="page-item m-0"><a class="page-link" href="#">4</a></li>
                                <li class="page-item m-0"><a class="page-link" href="#">5</a></li> -->
                                <li class="page-item m-0">
                                    <a class="page-link" href="#"><i class="fas fa-chevron-right"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>


                </div>
            </section>
        </div>

        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/MainHeader.vue'
import Footer from '@/components/common/Footer.vue'
export default {
  components:{
    Header, Footer
  },
  data(){
    return{
        search: '',
        list: [
            {
                idx: 2,
                title: "에스에이지카타운, 공유주차 플랫폼 활성화를 위한 업무제휴",
                writer: "원성글로벌",
                views: 98,
                date: "2023.09.08",
            },
            {
                idx: 1,
                title: "탐코퍼레잇견, 원성글로벌과 골프통합관라 플랫폼 서비스 업무 제휴",
                writer: "원성글로벌",
                views: 108,
                date: "2023.04.27",
            },
        ]
    }
  }
}
</script>

