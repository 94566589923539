<template>
    <section class="page-header parallax page-header-text-light mb-0 bg_img black_cover" :style="`background-image: url(${require(`@/assets/img/company/company.jpg`)})`">
        <div class="container pt-3 py-lg-5 my-lg-5 ">
            <!-- <div class="row pt-lg-5 mt-lg-5">
                <div class="col-md-12">
                    <ul class="breadcrumb" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="100">
                        <li><router-link to="/">Home</router-link></li>
                        <li><router-link to="/company">company</router-link></li>
                        <li class="active">{{title}}</li>
                    </ul>
                </div>
            </div> -->
            <div class="row">
                <div class="col-md-12">
                    <p class="font-weight-bold display-4 mb-4">{{title}}</p>
                    <p class="lead" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="400" v-html="desc"></p>
                </div>
            </div>
        </div>
        
    </section>
</template>
<script>
export default{
    props:{
        title:{
            type:String
        },
        desc:{
            type:String,
            require:false
        }
    }
}
</script>
<style lang="scss" scoped>
#contact span{
    color: #2388ed;
}
#contact:hover span,
#contact:hover i{
    color: #fff;
}
@media (max-width: 575.9px){
    .page-header .display-4{
        font-size: 23px;
    }
}
</style>