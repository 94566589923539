<template>
    <div class="body">
        <Header />

        <div class="main pb-4">
            <section class="parallax page-header-text-light mb-0">
                <div class="text-left">
                    <div class="d-flex flex-column flex-sm-row">
                        <div class="w-100 px-0">
                            <div class="bg_img black_cover w-100 h-px-400 pt-5" :style="`background-image: url(${header_img});`">
                            </div>
                        </div>
                        <div class="w-100 mb-0 bg-444 py-5 d-flex align-items-center text-left px-0">
                            <div class="pl-3 pl-sm-5 w-100">
                                <p class="pt-5 mt-5 d-none d-sm-block"></p>
                                <h1 class="font-weight-bold text-white">{{category}}</h1>
                                <p class="lead opacity-50 text-white">{{sub_title}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div class="container">
                <div class="">
                    <div class="mt-5">
                        <article class="blog-post mb-4">
                            <h3>{{item.title}}</h3>
                            <div class="d-flex mb-3 mt-2">
                                <span class="post-date pr-3">{{item.date}}</span>
                                <span class="d-flex align-items-center px-3 border-left">
                                    <span class="mr-2">조회수</span> {{item.views}}
                                </span>
                            </div>
                            <hr>
                            <div class="desc mt-5" v-html="item.desc">
                            </div>
                        </article>
                    </div>
                </div>
                <div class="text-right mt-5">
                    <router-link :to="`/${this.$route.params.category}`" class="btn bg-f9f9f9">목록</router-link>
                </div>
                <hr>
                <div class="row mt-2">
                    <div class="col">
                        <router-link :to="`/post/news/${prev_item.idx}`" class="d-flex align-items-center py-1" v-if="item.idx === list.length">
                            <span class="text-primary mr-3 flex-shrink-0">이전글 <i class="fas fa-caret-up"></i></span>
                            <p class="d-flex justify-content-between w-100 mb-0">
                                {{prev_item.title}}
                                <span class="opacity-5">{{prev_item.date}}</span>
                            </p>
                        </router-link>
                        <span class="d-flex align-items-center py-1" v-else>
                            <span class="text-primary mr-3 flex-shrink-0">이전글 <i class="fas fa-caret-up"></i></span>
                            <p class="d-flex justify-content-between w-100 mb-0 opacity-5">
                                이전글이 없습니다.
                                <span class="opacity-5">{{prev_item.date}}</span>
                            </p>
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <router-link :to="`/post/news/${next_item.idx}`" class="d-flex align-items-center py-1" v-if="item.idx < list.length">
                            <span class="text-primary mr-3 flex-shrink-0">다음글 <i class="fas fa-caret-down"></i></span>
                            <p class="d-flex justify-content-between w-100 mb-0">
                                {{next_item.title}}
                                <span class="opacity-5">{{next_item.date}}</span>
                            </p>
                        </router-link>
                        
                        <span class="d-flex align-items-center py-1" v-else>
                            <span class="text-primary mr-3 flex-shrink-0">다음글 <i class="fas fa-caret-up"></i></span>
                            <p class="d-flex justify-content-between w-100 mb-0 opacity-5">
                                다음글이 없습니다.
                                <span class="opacity-5">{{prev_item.date}}</span>
                            </p>
                        </span>
                    </div>
                </div>
            </div>

        </div>

        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/MainHeader.vue'
import Footer from '@/components/common/Footer.vue'
export default {
  components:{
    Header, Footer
  },
  watch:{
    '$route' (to, from) { 
        this.getList();
    }
  },
  mounted(){
    const news_list = [
        {
            idx: 1,
            title: "탐코퍼레잇견, 원성글로벌과 골프통합관라 플랫폼 서비스 업무 제휴",
            writer: "원성글로벌",
            views: 108,
            date: "2023.04.27",
            desc: `골프통합플랫폼 전문기업 (주)탐코퍼레이션(황정현 대표이사)는 (주)원성글로벌(박상현 대표이사)와 지난 15일 본사에서
스크린골프 골프통합관리 플랫품 서비스를 위한 업무협약을 체결했다고 밝혔다.
<br><br>
(주)탐코퍼레이션은 자체 개발한 골프통합 서비스플랫폼을 국내 560만 골퍼유저에게 부킹, 골프투어, 통합스코어,
셀프 체크인아웃 정산 등 골프 관련 서비스 플랫폼 개발을 하고 있으며, 또한 원성글로벌은 전자결제 금융 서비스,
IoT 토탈 결제 솔루션 서비스,빅데이터 및 AI기반 구독형 서비스인 IoT 공유주차 및 법률 정보 공유 플랫폼 등을 개발하는 전문기업이다.
<br><br>
양사는 이번 업무협약을 통해 보다 좋은 골프통합 관리 서비스플랫폼을 국내골퍼유저 및 골프장들에게 제공할 계획이다.
<br><br>
황정현 대표와 박상현 대표는 한 목소리로  "AI 기술을 접목시켜 골프 서비스를 한 단계 업그레이드하는 계기가 될 것으로 기대하며,
국내는 물론 해외 고객 모두가 보다 큰 즐거움과 새로운 경험을 할 수 있도록 혁신적인 서비스를 출시하는데 집중해 나갈 것"이라고 말했다.
<br><br>
https://www.gokorea.kr/news/articleView.html?idxno=740879.`
        },
        {
            idx: 2,
            title: "에스에이지카타운, 공유주차 플랫폼 활성화를 위한 업무제휴",
            writer: "원성글로벌",
            views: 98,
            date: "2023.09.08",
            desc: `에스에이지카타운(노면욱 대표)와 원성글로벌(장주현 대표)이 양사가 보유한 모빌리티 솔루션과 주차면 제공을 기반으로
공유주차 서비스 가맹점 유치 및 이용 활성화를 위한 업무 협약을 체결했다고 밝혔다.
<br><br>
이번 업무 협약식은 원성글로벌 사무실에서 열렸다. 이날 행사에는 노면욱 에스에이지카타운 대표와 장주현 원성글로벌 대표를
포함한 양사 관계자들이 참석했다.
<br><br>
원성글로벌은 모빌리티 공유 플랫폼 운영 관리 전문 기업으로, 공유주차 서비스를 기반으로 모빌리티관련 부가서비스 제공으로
모빌리티 사업 영역을 확장하고 있다. 
<br><br>
이번 업무협약에 따라 양사는 수도권을 기반으로 모빌리티 공유 플랫폼 서비스 활성화를 한다는 방침이다. 특히 에스에지카타운은
SK렌터카 등 대형 기업에게 주차면을 제공하고 있는 기업이다.
<br><br>
장주현 대표는 “모빌리티 공유 플랫폼 활성화를 위해, 공유 주차면을 이용하면서 세차/광택/경정비 서비스 등 부가서비스 제공＂을
통해 One-Stop 모빌리티 서비스를 제공할 방침이다.`
        },
    ];
    const data = [];
    const corpoDisclosure = [
        {
            idx: 1,
            title: "제1기 정기주주총회 소집 공고",
            writer: "원성글로벌",
            views: 60,
            date: "2023.02.20",
            desc: `제1기 정기 주주 총회 소집 공고
<br><br>
주주님의 건승과 댁내의 평안을 기원 합니다.
당사는 상법 제363조와 정관 제20조에 의거 제1기 정기주주초회를 아래와 같이 개최 하오니
참석하여 주시기 바랍니다.
<br><br>
<p class="text-center">-  아     래  - </p>
<br><br>
1. 일 시 : 2023년 03월 14일(화) 10:00<br>
2. 장 소 : 경기도 하남시 미사대로 520, 현대지식산업센터 한강미사2차 C동 804호<br>
3. 총회 목적사항<br>
   가. 보고사항 : 감사보고 및 영업보고<br>
   나. 결의사항<br>
      - 제1호 의안 : 2022년 사업년도 재무제표 승인의 건<br>
      - 제2호 의안 : 이사 보수한도 승인의 건<br>
4. 임시주주총회 참석시 준비물<br>
   가. 본인 : 본인 신분증, 정기주주총회 참석장<br>
   나. 대리인 : 위임장(주주와 대리인 인적사항 기재, 인감 날인), 주주인감증명서,<br>
                  대리인 신분증, 정기주주총회 참석장<br>
                  <br><br>
                  2023. 02. 20
                  <br><br>
                  <br><br>
주식회사 원성글로벌
대표이사 장  주  현

`
        },
        {
            idx: 2,
            title: "제1기 정기주주총회 결과 공고",
            writer: "원성글로벌",
            views: 56,
            date: "2023.03.14",
            desc: `제1기 정기 주주 총회 결과 공고
<br><br>
1. 일 시 : 2023년 03월 14일(화) 10:00~10:30<br>
2. 장 소 : 경기도 하남시 미사대로 520, 현대지식산업센터 한강미사2차 C동 804호<br>
3. 정기주주총회 부의안건 및 결과<br>
   가. 제1호 의안 : 2022년 사업년도 재무제표 승인의 건<br>
       <span class="ps-3">- 참석 주주 전원 이의 없이 승인 가결<br></span>
   나. 제2호 의안 : 이사 보수한도 승인의 건<br>
       <span class="ps-3">- 참석 주주 전원 이의 없이 승인 가결<br></span>
       <br><br>
       <br><br>
                            2023. 03. 14
                            <br><br>
                            <br><br>
                      주식회사 원성글로벌
                      대표이사 장  주  현
`
        },
    ]
    
    let cate = this.$route.params.category;
    switch (cate){
        case 'news':this.list = news_list;
        break;
        case 'data':this.list = data;
        break;
        case 'corpo-disclosure':this.list = corpoDisclosure;
        break;
    }
    this.getList();
  },
  data(){
    return{
        header_img: '',
        sub_title: '',
        list: [],
        item: {},
        prev_item: {
        },
        next_item: {
        }
    }
  },
  computed:{
    category(){
        let cate = this.$route.params.category;
        switch (cate) {
            case 'news':
                cate = 'news'
                this.header_img = require(`@/assets/img/bg/pr_1.jpg`);
                this.sub_title = '원성글로벌의 소식을 전해드립니다.';
                break;
            case 'data':
                cate = '자료실'
                this.header_img = require(`@/assets/img/bg/pr_2.jpg`);
                this.sub_title = '원성글로벌의 자료를 열람하실 수 있습니다.';
                break;
            case 'corpo-disclosure':
                cate = '기업공시'
                this.header_img = require(`@/assets/img/bg/pr_3.jpg`);
                this.sub_title = '기업공시에 대한 안내입니다.';
                break;
            default:
                this.header_img = require(`@/assets/img/bg/pr_1.jpg`);
                break;
        }
        return cate;
    }
  },
  methods:{
    getList(){
        if (this.$route.params.idx) {
            const code = this.$route.params.idx;

            this.item = this.list[code - 1];
            if (this.item.idx > 1) {
                this.prev_item = this.list[code - 2];
            } else {
                this.prev_item = {}; // 이전글이 없을 때 빈 객체로 초기화
            }

            if (code < this.list.length) {
                this.next_item = this.list[code]; // 다음글이 있을 때 할당
            } else {
                this.next_item = {}; // 다음글이 없을 때 빈 객체로 초기화
            }
        }
    }
  }
}
</script>

<style>

</style>