<template>
    <div class="body">
        <Header />
        <section class="page-header parallax page-header-text-light mb-0 bg_img black_cover" :style="`background-image: url(${require(`@/assets/img/bg/4.jpg`)})`">
            <div class="container py-5 my-5">
                <div class="row pt-5 mt-5">
                    <div class="col-md-12">
                        <ul class="breadcrumb" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="100">
                            <li><router-link to="/">Home</router-link></li>
                            <li><router-link to="/company">business</router-link></li>
                            <li class="active">사업 소개</li>
                        </ul>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <p class="font-weight-bold display-4">사업 소개</p>
                    </div>
                </div>
            </div>
        </section>
        <div class="main">
            <section class="section">
                <div class="container pt-lg-5">
                    <div class="unit rt pt-5 pt-lg-0">
                        <div class="text-center"><img :src="`${require(`@/assets/img/business/${selectItem.title_img}`)}`" height="50" class="mx-auto mb-5"></div>
                        <div class="position-relative wrap pt-4 pt-lg-0">
                            <div class="position-absolute title_img">
                                
                            </div>
                            <div class="txt_box">
                                <div class="bg-light-blue position-absolute w-100 h-100"></div>
                                <div class="txt p-5">
                                    <div class="inner mt-3">
                                        <h4 class="mb-4 fw-700">{{selectItem.sub_title}}</h4>
                                        <h5 class="lh-px-24 mb-5">{{selectItem.desc}}</h5>
                                        <strong class="text-primary h6 fw-700">서비스 특장점</strong>
                                        <ul class="text-dark mt-3">
                                            <li v-for="(item,index) in selectItem.advantages" :key="index" class="mb-4">
                                                <div class="h6 fw-700">
                                                    <span>{{index+1}}. </span>
                                                    {{item.title}}
                                                </div>
                                                <div class="mt-1">
                                                    {{item.desc}}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div class="img_box bg_img" :style="`background-image: url(${require(`@/assets/img/business/${selectItem.img}`)})`">
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/MainHeader.vue'
import Footer from '@/components/common/Footer.vue'
export default {
  components:{
    Header, Footer
  },
  data(){
    return{
        item:[
            {
                title: '더굿페이',
                title_img:'04.png',
                sub_title:'The Good Pay만의 NFC 간편결제 서비스 제공',
                desc:`The Good Pay 서비스는 기존 CAT 단말기에 Swipe, IC카드 삽입 리딩의 한계를 극복한 스마트폰의 NFC 및 IC(RF) Card 기반 모바일 결제 서비스입니다.`,
                img:'business2.png',
                advantages: [
                    {
                        title: '안전성',
                        desc: '최고의 안전성을 갖춘 전자결제 서비스'
                    },
                    {
                        title: '편리성',
                        desc: '최상의 편의성, 호환성을 제공하는 전자결제 서비스'
                    },
                    {
                        title: '유동성',
                        desc: '어떤 상황이라도 결제가 가능하도록 지불수단 지원'
                    },
                ]
            },
        ]
    }
  },
  computed:{
    selectItem(){
        return this.item[0];
    },
  },
}
</script>
<style scoped>
    .bg-light-blue{
        background-color: #f1f3f7;
    }
    .unit{
        margin-bottom: 250px;
    }
    .txt_box{
        width: 65%;
    }
    .txt_box .txt{
        position: relative;
        z-index: 2;
    }
    .img_box{
        width: 40%;
        height: 0;
        padding-bottom: 40%;
        z-index: 2;
        box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
    }
    .rt .img_box, .lt .txt_box{
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
    .rt .img_box, .lt .txt_box{
        right: 0;
    }
    .rt .txt_box{
        position: relative;
    }
    .rt .txt_box .inner{
        margin-right: 10%;
    }
    .lt .txt_box .inner{
        margin-left: 20%;
    }
    .title_img{
        left: 20px;
        bottom: 100%;
        transform: translateY(50%);
        z-index: 10;
    }

    @media (max-width: 991.9px){
        .wrap{
            display: flex;
            flex-direction: column;
        }
        .txt_box{
            order: 2;
            width: 100%;
            margin-top: -70px;
        }
        .img_box{
            order: 1;
            width: 80%;
            padding-bottom: 80%;
            margin: 0 auto;
        }
        .rt .img_box, .lt .txt_box{
            position: relative;
            transform: initial;        
        }
        .rt .txt_box .inner{
            margin-right: 0%;
            padding-top: 100px;
        }
        .lt .txt_box .inner{
            margin-left: 0%;
            padding-top: 100px;
        }
        
        .title_img{
            left: 50%;
            transform: translate(-50%, 0%);
            z-index: 10;
        }
    }
    
@media (max-width: 575.9px){
    .page-header .display-4{
        font-size: 23px;
    }
}
</style>