<template>
    <div class="body">
        <Header />

        <div class="main">
            
            <CompanyHeader :title="title" />
            <section id="ideology" class="section">
                <div class="container">
                    <div class="row text-center mb-4">
                        <div class="col-md-10 col-lg-7 col-xl-6 mx-auto">
                            <div class="overflow-hidden">
                                <span class="d-block top-sub-title text-color-primary" data-appear-animation="maskUp">Management Ideology</span>
                            </div>
                            <div class="overflow-hidden">
                                <h2 class="font-weight-bold mb-3" data-appear-animation="maskUp" data-appear-animation-delay="200">경영이념</h2>
                            </div>
                        </div>
                    </div>
                    
                    <img class="w-100" src="https://images.unsplash.com/photo-1553877522-43269d4ea984?q=80&w=3870&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="">
                </div>
            </section>
            <section id="aboutus" class="section">
                <div class="container">
                    <div class="row text-center mb-4">
                        <div class="col-md-10 col-lg-7 col-xl-6 mx-auto">
                            <div class="overflow-hidden">
                                <span class="d-block top-sub-title text-color-primary" data-appear-animation="maskUp">Vision</span>
                            </div>
                            <div class="overflow-hidden">
                                <h2 class="font-weight-bold mb-3" data-appear-animation="maskUp" data-appear-animation-delay="200">비전</h2>
                            </div>
                        </div>
                    </div>
                    
                    <div class="flex-lg-row flex-column row mt-2">
                        <div class="col-lg-4 mb-4 mb-lg-0 cursor-pointer" @click="$router.push('/vision')">
                            <div class="icon-box icon-box-style-5">
                                <div class="icon-box-icon bg-light-3">
                                    <img width="42" height="42" src="vendor/linear-icons/briefcase.svg" alt=""/>
                                </div>
                                <div class="icon-box-info">
                                    <div class="icon-box-info-title mb-3">
                                        <h2 class="font-weight-bold text-5">MISSION</h2>
                                    </div>
                                    <p>고객과 함께 성장하고 나눔의 미덕이 있는 기업</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 mb-4 mb-lg-0 cursor-pointer" @click="$router.push('/core-value')" >
                            <div class="icon-box icon-box-style-5">
                                <div class="icon-box-icon bg-light-3">
                                    <img width="42" height="42" src="vendor/linear-icons/eye.svg" alt=""/>
                                </div>
                                <div class="icon-box-info">
                                    <div class="icon-box-info-title">
                                        <h2 class="font-weight-bold text-5">VISION</h2>
                                    </div>
                                    <p>차별화된 기술력과 통합 결제 솔루션 제공으로<br>Payment 시장을 선도할 수 있는 기업</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 mb-4 mb-md-0 cursor-pointer" @click="$router.push('/client')" >
                            <div class="icon-box icon-box-style-5">
                                <div class="icon-box-icon bg-light-3 pt-2">
                                    <img width="42" height="42" src="vendor/linear-icons/diamond.svg" alt=""/>
                                </div>
                                <div class="icon-box-info">
                                    <div class="icon-box-info-title">
                                        <h2 class="font-weight-bold text-5">VALUE</h2>
                                    </div>
                                    <p>혁신 / 도전 / 소통</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section class="section" id="keyword">
                <div class="container">
                    <div class="bg-light-3 rounded-0 rounded-sm-5 row p-5 text-center">
                        <div class="col-lg-4">
                            <i class="far fa-3x fa-lightbulb"></i>
                            <h4 class="mb-2 mt-3">사명</h4>
                            <p>왜 존재하는가? (MISSION)</p>
                        </div>
                        
                        <div class="col-lg-4 border-left border-right my-5 my-lg-0">
                            <i class="far fa-3x fa-globe"></i>
                            <h4 class="mb-2 mt-3">비전</h4>
                            <p>무엇이 될 것인가? (VISION)</p>
                        </div>
                        
                        <div class="col-lg-4">
                            <i class="far fa-3x fa-bullhorn"></i>
                            <h4 class="mb-2 mt-3">핵심가치</h4>
                            <p>어떻게 일할 것인가? (CORE VALUE)</p>
                        </div>

                    </div>
                </div>
            </section>
            <section class="section mb-8">
                <div class="container">
                    
                    <!-- <div class="row" data-appear-animation="fadeInUpShorter" data-appear-animation-delay="600">
                        <div class="col-md-4 mb-5">
							<div class="icon-box icon-box-style-5">
								<div class="icon-box-icon icon-box-icon-square bg-primary">
									<i class="lnr lnr-briefcase"></i>
								</div>
								<div class="icon-box-info">
									<div class="icon-box-info-title">
										<h4>SIMPLE</h4>
									</div>
									<p>통합 전자결제 솔루션 기반 NFC, QR code, Bar code 등 간편결제 서비스를 제공함으로써 언제 어디서나 간편하게 빠르게 결제</p>
								</div>
							</div>
						</div>
                        <div class="col-md-4 mb-5">
							<div class="icon-box icon-box-style-5">
								<div class="icon-box-icon icon-box-icon-square bg-primary">
									<i class="lnr lnr-laptop-phone"></i>
								</div>
								<div class="icon-box-info">
									<div class="icon-box-info-title">
										<h4>SECURE</h4>
									</div>
									<p>클라우드 보안관련 정보보안 표준 적용
전자결제를 통한 결제 승인/취소 시
카드정보를 저장하지 않아 해킹,
위조/복제, 정보유출의 위험성이 없는
우수한 보안성</p>
								</div>
							</div>
						</div>
                        <div class="col-md-4 mb-5">
							<div class="icon-box icon-box-style-5">
								<div class="icon-box-icon icon-box-icon-square bg-primary">
									<i class="lnr lnr-chart-bars"></i>
								</div>
								<div class="icon-box-info">
									<div class="icon-box-info-title">
										<h4>SMART</h4>
									</div>
									<p>온라인/오프라인 서비스 O2O
쇼핑몰 및 플랫폼 등에서
고객이 스마트한 결제
편의성 제공
</p>
								</div>
							</div>
						</div>
                    </div> -->
                    <div class="row mb-5 mb-lg-0 pb-4 pb-lg-0">
                            <div class="col">
                                <div class="steps steps-primary steps-style-4">
                                    <div class="dots" data-appear-animation="fadeIn" data-appear-animation-delay="1200">
                                        <img class="dots-mask" src="@/assets/img/svg/steps-dots-bg-light.svg" alt="" />
                                        <div class="dots-color"></div>
                                        <div class="dots-color-dark"></div>
                                    </div>
                                    <div v-for="(item, index) in items" :key="index" :class="['item', 'no-bg-color', { active: index === activeIndex }]" :style="`background-image: url(${require(`@/assets/img/company/vision_${index + 1}.jpg`)})`"></div>
                                </div>
                            </div>
                        </div>
                        <div class="row text-center justify-content-center">
                            <div class="col-md-9 col-lg-4 mb-5 mb-lg-0" data-link-id="IoT">
                                <h3 class="text-4 font-weight-extra-bold mb-3">SIMPLE</h3>
                                <p class="px-4">통합 전자결제 솔루션 기반 NFC, QR code, Bar code 등 간편결제 서비스를 제공함으로써 언제 어디서나 간편하게 빠르게 결제
                                </p>
                            </div>
                            <div class="col-md-9 col-lg-4 mb-5 mb-lg-0" data-link-id="ourmission">
                                <h3 class="text-4 font-weight-extra-bold mb-3">SECURE</h3>
                                <p class="px-4">클라우드 보안관련 정보보안 표준 적용 전자결제를 통한 결제 승인/취소 시 카드정보를 저장하지 않아 해킹, 위조/복제, 정보유출의 위험성이 없는 우수한 보안성                            </p>
                            </div>
                            <div class="col-md-9 col-lg-4" data-link-id="ourservices">
                                <h3 class="text-4 font-weight-extra-bold mb-3">SMART</h3>
                                <p class="px-4">온라인/오프라인 서비스 O2O 쇼핑몰 및 플랫폼 등에서 고객이 스마트한 결제 편의성 제공</p>
                            </div>
                        </div>
                </div>
            </section>
        </div>

        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/MainHeader.vue'
import CompanyHeader from './CompanyHeader.vue'
import Footer from '@/components/common/Footer.vue'
export default {
  components:{
    Header, CompanyHeader, Footer
  },
  data(){
    return{
        title:'경영이념 및 비전',
        items: [
            { image: '@/assets/img/company/vision_1.jpg' },
            { image: '@/assets/img/company/vision_2.jpg' },
            { image: '@/assets/img/company/vision_3.jpg' }
        ],
        activeIndex: 0
    }
  },
  mounted() {
    setInterval(() => {
      this.activeIndex = (this.activeIndex + 1) % this.items.length;
    }, 5000);
  }
}
</script>
<style scoped>
@media screen and (max-width:992px) {
    #keyword .border-left,#keyword .border-right{
        border-left: none !important;
        border-right: none !important;
    }   
}
</style>