<template>
    <div class="body">
        <Header />

        <div class="main">
            <CompanyHeader :title="title" />
            <section class="py-5">
                <div class="container">
                    <ul class="portfolio-list portfolio-list-style-2 d-flex flex-wrap">
                        <li class="col-sm-6 col-md-4 p-0 mb-3" v-for="(item, index) in client" :key="index">
                        <div class="portfolio-item text-center h-100 d-flex align-items-center justify-content-center">
                            <!-- <a href="#" target="_blank" class="d-block"> -->
                            <span class="image-frame image-frame-style-1 mb-3">
                                <span class="image-frame-wrapper">
                                <img :src="item.src" class="img-fluid" alt="" style="min-width:100%; max-width:200px;">
                                </span>
                            </span>
                            <!-- </a> -->
                        </div>
                        </li>
                    </ul>
                </div>
            </section>
        </div>
        
        <Footer />
    </div>
</template>

<script>
import Header from '@/components/common/MainHeader.vue'
import CompanyHeader from './CompanyHeader.vue'
import Footer from '@/components/common/Footer.vue'
export default {
  components:{
    Header, CompanyHeader, Footer
  },
  data(){
    return{
        title:'파트너',
        client:[
            {
                src:require('@/assets/img/client/SK쉴더스_로고.jpg'),
            },
            {
                src:require('@/assets/img/client/굿어스데이터_로고.jpg')
            },
            {
                src:require('@/assets/img/client/네이버클라우드_로고.png')
            },
            {
                src:require('@/assets/img/client/뱅크드인_로고.png')
            },
            {
                src:require('@/assets/img/client/에스에이지카타운_로고.png')
            },
            {
                src:require('@/assets/img/client/원진회계법인_로고.jpg')
            },
            {
                src:require('@/assets/img/client/탐코퍼레이션_로고.png')
            },
            {
                src:require('@/assets/img/client/화우_로고.jpg')
            },
            {
                src:require('@/assets/img/client/smartro.png')
            },
        ]
    }
  }
}
</script>
